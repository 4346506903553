import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableGPULoadable';
import Accordion from 'components/accordion';
import AccentBox from 'components/accentBox';
import BasicTable from "components/basicTable";
import Socials from 'components/socials';
import Meyasu from 'components/gpu/meyasu';
import GeforceMatsubi from 'components/gpu/geforce_matsubi';
import Image from "components/image";
import Twenty from "react-twentytwenty";
import Card from "components/card";
import TOC from "components/toc";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "gpu/geforce-vs-radeon"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/gpu/geforce-vs-radeon.png"}) { ...eyecatchImg },
    rtxoff: file(relativePath: { eq: "gpu/rtxoff.png"}) { ...normalImg },
    rtxon: file(relativePath: { eq: "gpu/rtxon.png"}) { ...normalImg },
    dlss_sampling: file(relativePath: { eq: "gpu/dlss_sampling.png"}) { ...normalImg },
    rendering: file(relativePath: { eq: "cpu/rendering.png"}) { ...normalImg },
    allGeforceVsRadeonJson {
      nodes {
        name
        passmark
        vmark
        fullHd
        qhd
        fourK
        tdp
        price
        cospa
        year
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`NVIDIA GeforceとAMD Radeonの違いを徹底比較`}</h1>
    <time itemProp="datePublished" dateTime="Sat Feb 08 2025 00:21:48 GMT+0900 (日本標準時)">更新日:2025年2月8日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="GeforceとRadeonの性能比較" mdxType="Image" />
    <p>{`コンシューマ向けGPUであるNVIDIA GeforceとAMDのRadeon。RT CoreとRay Accelerator、DLSSとFSRのように、それぞれ同じような特徴の機能を異なったマーケティング名で出してきているため、その違いが良く分からないケースが多い。`}</p>
    <p>{`また当サイトでは`}<Link to="/gpu/" mdxType="Link">{`GPU性能比較表`}</Link>{`により、ベンチマークや実環境においてゲームでどの程度のフレームレートが出るかを表示しているが、特定アルゴリズムでのレンダリング性能やメモリアクセス性能など表では表現できない部分も多い。`}</p>
    <p>{`そこで本ページではGeforceとRadeonを包括的に比較し、パソコン購入時の判断の一助となる事を目指す。依然としてベンチマークは重要であるため、最後にGeforceとRadeonの性能比較表も載せる。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "GeforceとRadeonの比較をテクニカルを中心に俯瞰する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Geforce%E3%81%A8Radeon%E3%81%AE%E6%AF%94%E8%BC%83%E3%82%92%E3%83%86%E3%82%AF%E3%83%8B%E3%82%AB%E3%83%AB%E3%82%92%E4%B8%AD%E5%BF%83%E3%81%AB%E4%BF%AF%E7%9E%B0%E3%81%99%E3%82%8B",
        "aria-label": "GeforceとRadeonの比較をテクニカルを中心に俯瞰する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GeforceとRadeonの比較をテクニカルを中心に俯瞰する`}</h2>
    <p>{`まず比較を一覧できるように、要素ごとにGeforceとRadeonの技術やアプリなどを列挙してみる。片方がもう一方より優れている項目に対してはハイライトをして示した。`}</p>
    <BasicTable mdxType="BasicTable">
  <caption>GeforceとRadeonの機能表</caption>
  <thead>
    <tr>
      <th></th>
      <th>Nvidia Geforce</th>
      <th>AMD Radeon</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>アーキテクチャ</td>
      <td><a href="https://www.nvidia.com/ja-jp/design-visualization/ada-lovelace-architecture/" target="_blank" rel="nofollow noopener">Ada Lovelace</a></td>
      <td><a href="https://www.amd.com/ja/technologies/rdna" target="_blank" rel="nofollow noopener">RDNA3</a></td>
    </tr>
    <tr>
      <td>レイトレーシング</td>
      <td style={{
            backgroundColor: 'rgb(237 247 131)'
          }}>RT Core演算</td>
      <td>Ray Accelerator</td>
    </tr>
    <tr>
      <td>超解像技術</td>
      <td style={{
            backgroundColor: 'rgb(237 247 131)'
          }}><a href="https://www.nvidia.com/ja-jp/geforce/technologies/dlss/" target="_blank" rel="nofollow noopener">DLSS(Deep Learning Super Sampling)</a></td>
      <td><a href="https://www.amd.com/ja/technologies/fidelityfx-super-resolution" target="_blank" rel="nofollow noopener">FSR(FidelityFX™ Super Resolution)</a></td>
    </tr>
    <tr>
      <td>プログラミング</td>
      <td style={{
            backgroundColor: 'rgb(237 247 131)'
          }}><a href="https://developer.nvidia.com/about-cuda" target="_blank" rel="nofollow noopener">CUDA</a>/<a href="https://www.khronos.org/opencl/" target="_blank" rel="nofollow noopener">OpenCL</a></td>
      <td><a href="https://www.khronos.org/opencl/" target="_blank" rel="nofollow noopener">OpenCL</a></td>
    </tr>
    <tr>
      <td>キャッシュ</td>
      <td></td>
      <td style={{
            backgroundColor: 'rgb(237 247 131)'
          }}>Infinity Cache</td>
    </tr>
    <tr>
      <td>メモリアクセス</td>
      <td><a href="https://www.nvidia.com/en-us/geforce/news/geforce-rtx-30-series-resizable-bar-support/" target="_blank" rel="nofollow noopener">Resizable Bar</a></td>
      <td style={{
            backgroundColor: 'rgb(237 247 131)'
          }}><a href="https://www.amd.com/ja/technologies/smart-access-memory" target="_blank" rel="nofollow noopener">SMART Access Memory</a></td>
    </tr>
    <tr>
      <td>3Dレンダリング</td>
      <td style={{
            backgroundColor: 'rgb(237 247 131)'
          }}><a href="https://developer.nvidia.com/rtx/ray-tracing/optix" target="_blank" rel="nofollow noopener">OptiX Renderer</a></td>
      <td><a href="https://www.amd.com/ja/technologies/radeon-prorender" target="_blank" rel="nofollow noopener">Radeon ProRender</a></td>
    </tr>
    <tr>
      <td>アプリ</td>
      <td style={{
            backgroundColor: 'rgb(237 247 131)'
          }}><a href="https://www.nvidia.com/ja-jp/geforce/broadcasting/broadcast-app/" target="_blank" rel="nofollow noopener">NVIDIA BroadCast APP</a></td>
      <td></td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`この表をもとにそれぞれの要素について説明していく。`}</p>
    <h3 {...{
      "id": "レイトレーシング-RT-Core-vs-Ray-Accelerator",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AC%E3%82%A4%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B7%E3%83%B3%E3%82%B0-RT-Core-vs-Ray-Accelerator",
        "aria-label": "レイトレーシング RT Core vs Ray Accelerator permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`レイトレーシング (RT Core vs Ray Accelerator)`}</h3>
    <p>{`レイトレーシングは光(Ray)の経路を追跡(Tracing)して、最終的にモニタに映し出される画素を決定するレンダリングの技術であり、`}<strong parentName="p"><em parentName="strong">{`光の描写を現実世界に近づけ、より実写に近い映像を作り出すことができる`}</em></strong>{`。`}</p>
    <p>{`百聞は一見に如かずなので、次のマインクラフトの絵を比べてみて欲しい。(マウスやタップでぐりぐり可能)。右がレイトレーシングOnの状態であるが、描写が大きく変わることが分かるだろう。`}</p>
    <Twenty left={<Image {...props} name="rtxoff" mdxType="Image" />} right={<Image {...props} name="rtxon" mdxType="Image" />} mdxType="Twenty" />
    <p>{`レイトレーシングは膨大な計算量が必要となるため、フレームレートが極端に落ちてしまう。しかし`}<strong parentName="p"><em parentName="strong">{`NvidiaのRTXシリーズはRT Coreというレイトレーシングの演算に特化した半導体チップを搭載`}</em></strong>{`しており、ハードウェアレベルの最適化を行っていないRadeonと比べて優位性が高い。`}</p>
    <h3 {...{
      "id": "超解像技術-DLSS-vs-FSR",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%B6%85%E8%A7%A3%E5%83%8F%E6%8A%80%E8%A1%93-DLSS-vs-FSR",
        "aria-label": "超解像技術 DLSS vs FSR permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`超解像技術 (DLSS vs FSR)`}</h3>
    <p>{`超解像は低解像度の画像から高解像度の画像を作り出す技術である。この要素技術として、膨大な画像パターンをディープラーニングで学習させ、高解像度画像の再現率を大幅に向上させたものがNvidiaの`}<em parentName="p">{`DLSS(Deep Learning Super Sampling)`}</em>{`である。`}</p>
    <p>{`ディープラーニングはアルゴリズムは単純であるが計算量は非常に大きい。この計算量を瞬時に捌くために`}<strong parentName="p"><em parentName="strong">{`NvidiaはTensor CoreというAI計算に特化した専用ハードウェアを搭載している`}</em></strong>{`。`}</p>
    <Image {...props} name="dlss_sampling" caption="サンプリングイメージ" mdxType="Image" />
    <p>{`一方でRadeonの`}<em parentName="p">{`FSR`}</em>{`はより汎用的な超解像技術であるため、幅広いグラフィックボードに適用可能という特色はあるものの、性能はDLSSに遠く及ばない。`}</p>
    <h3 {...{
      "id": "プログラミング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0",
        "aria-label": "プログラミング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`プログラミング`}</h3>
    <p>{`GPUを使ったアプリの開発を行いたいのならば、OpenCLよりCUDAの方がよりポピュラーである。`}</p>
    <p>{`OpenCLはオープンソースの技術であり、GeforceとRadeonの両方で利用することができるが、CUDAはNvidiaが開発したGPUを簡単に扱うことができるToolKitであり、Radeonでは利用ができない。`}</p>
    <p>{`このため`}<strong parentName="p"><em parentName="strong">{`GPUを使った何かしらの開発がしたいのならば、選択肢が広いGeforce一択`}</em></strong>{`であろう。`}</p>
    <h3 {...{
      "id": "キャッシュメモリ機構",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E3%83%A1%E3%83%A2%E3%83%AA%E6%A9%9F%E6%A7%8B",
        "aria-label": "キャッシュメモリ機構 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`キャッシュ/メモリ機構`}</h3>
    <p>{`GPUは自身で数ギガバイトのメモリ(VRAM)を抱えているが、GPUのCoreとVRAMのやり取りはそれほど高速ではないため、キャッシュと呼ばれる小容量であるがVRAMよりも高速なメモリをGPUのCore付近に抱えている。`}</p>
    <p>{`GPUに近い順にL1キャッシュ、L2キャッシュ、L3キャッシュと呼ばれ、近い順に小容量で高速になる。`}<strong parentName="p"><em parentName="strong">{`Infinity CacheはこのうちのL3キャッシュを飛躍的に強化した`}</em></strong>{`ものであり、大容量のデータ転送を高速化できる。`}</p>
    <p>{`また、ゲーム中はCPUとGPUの間でもメモリを介して多くのやり取りが行われるが、`}<strong parentName="p"><em parentName="strong">{`CPUからGPUのメモリの全てにアクセスできるようにした技術がRadeonのスマートアクセスメモリ`}</em></strong>{`である。これによりCPU、GPU間の通信がよりスムーズに行うことができる。`}</p>
    <p>{`Ryzen 5000シリーズ以降のCPUにしかスマートアクセスメモリは有効とならないという制約があるが、同種のNvidiaが採用している`}<em parentName="p">{`Resizable Toolbar`}</em>{`よりも高速である。`}</p>
    <p>{`これらのキャッシュ・メモリ機構により、ゲーム中にフレームレートを安定させやすい、すなわち`}<strong parentName="p"><em parentName="strong">{`重くなりにくいという効果がゲーム中で得られやすい`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "3Dレンダリング-OptiX-vs-Pro-Render",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3D%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0-OptiX-vs-Pro-Render",
        "aria-label": "3Dレンダリング OptiX vs Pro Render permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3Dレンダリング (OptiX vs Pro Render)`}</h3>
    <Image {...props} name="rendering" alt="レンダリングの過程" mdxType="Image" />
    <p>{`Blenderなどのアプリケーションを用い、3Dモデリングでリアルな映像表現を実現するためにもレイトレーシングは欠かせない技術である。`}</p>
    <p>{`Nvidiaは`}<em parentName="p">{`OptiX`}</em>{`というレイトレーシング専用のレンダリングエンジンを提供しており、リアル映像表現をGeforceを用いて高速に描画することができる。`}</p>
    <p>{`一方でAMDも`}<em parentName="p">{`Pro Render`}</em>{`というレンダリングエンジンを提供しているが、レイトレーシング性能ではRadeonはGeforceに及ばないため、レンダリングは遅く、映像の再現性も低い。`}</p>
    <p>{`3DCG作成はモデリング作業を行いレンダリング結果を確認する繰り返しとなり、`}<strong parentName="p"><em parentName="strong">{`プレビューの忠実度と速度は非常に重要となるため、Geforceに軍配が上がる`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "アプリ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%A2%E3%83%97%E3%83%AA",
        "aria-label": "アプリ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`アプリ`}</h3>
    <p>{`Geforce RTXシリーズの機能をフルに使ったキラーアプリが`}<em parentName="p">{`NVIDIA Broadcastアプリ`}</em>{`である。`}</p>
    <p>{`アプリというよりは、OBS StudioやTwitch Studioのような配信系やZoom、Teams、WebEx、Skypeのようなビデオ会議系のアプリと、使用するカメラ、マイクの`}<strong parentName="p"><em parentName="strong">{`間に入ってノイズ除去や周囲の背景を変えたりぼかしたりするソフトウェア`}</em></strong>{`である。`}</p>
    <p>{`TensorコアによるAI系処理能力をフルに活用しており、例えば`}<strong parentName="p"><em parentName="strong">{`隣で家族がドライヤーをしていたとしても全く雑音が会話に入らない`}</em></strong>{`など、その能力は非常に高い。`}</p>
    <h2 {...{
      "id": "用途ごとにGeforceとRadeonを比較",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%94%A8%E9%80%94%E3%81%94%E3%81%A8%E3%81%ABGeforce%E3%81%A8Radeon%E3%82%92%E6%AF%94%E8%BC%83",
        "aria-label": "用途ごとにGeforceとRadeonを比較 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`用途ごとにGeforceとRadeonを比較`}</h2>
    <p>{`これである程度の前提知識は得られたと思うため、次は用途によってどちらを選ぶべきかを見極めていきたい。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <caption>GeforceとRadeonの機能表</caption>
  <thead>
    <tr>
      <th></th>
      <th>Nvidia Geforce</th>
      <th>AMD Radeon</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>美麗なグラフィック</td>
      <td>〇</td>
      <td></td>
    </tr>
    <tr>
      <td>Eスポーツ</td>
      <td></td>
      <td>〇</td>
    </tr>
    <tr>
      <td>GPUアプリ開発</td>
      <td>〇</td>
      <td></td>
    </tr>
    <tr>
      <td>クリエイティブ</td>
      <td>〇</td>
      <td>△</td>
    </tr>
    <tr>
      <td>ビジネス</td>
      <td>〇</td>
      <td></td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`まずは美しい映像でゲームをプレイしたい場合は`}<strong parentName="p"><em parentName="strong">{`レイトレーシングの性能が絶対的にモノを言う`}</em></strong>{`ためGeforce一択である。DLSSで4Kのアップスケーリングを行いレイトレーシングを有効にすることが最高峰のグラフィックを楽しむための王道である。`}</p>
    <p>{`同じゲームでも動きの速いFPSの場合はどうか？高解像度やレイトレーシングはフレームレートが落ちるため競技用として有効にしている人は聞いたことがない。代わりに高フレームレートが重要となるため、レイトレーシングも、高解像度向けの機能であるDLSSも出る幕は無い。`}<strong parentName="p"><em parentName="strong">{`コスパを考えると動きの速いEスポーツを行う場合にはRadeonが良い`}</em></strong>{`のではなかろうか。`}</p>
    <p>{`次にGPUを利用したアプリ開発。これは明確にCUDAが使えるGeforceで前述の通り一択である。`}</p>
    <p>{`次に3DCG作成などのクリエイティブ分野であるが、これもAdobeやBlendarなど一般人が良く使うソフトではGeforceに軍配が上がる。`}<strong parentName="p"><em parentName="strong">{`Geforceの方がRadeonよりもレンダリング能力が高く、トライアンドエラーを繰り返しやすい事に加え、動作も安定している`}</em></strong>{`。`}</p>
    <p>{`動作が安定している理由は使用ユーザが多いからである。GeForceとRadeon由来の不具合が２つあり、深刻度が同等だとすると、シェアが高いGeForceの修正をアプリ開発者が優先するからである。`}</p>
    <p>{`ただし、3DS Max、Energy、Medical、Simens NXなど多くの業務用アプリケーションのベンチマークである`}<a parentName="p" {...{
        "href": "https://gwpg.spec.org/benchmarks/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`SPECviewperf`}</a>{`によると、特に`}<strong parentName="p"><em parentName="strong">{`メモリの優位性によりRadeonの方が良い結果を示しているものも多く、ソフトによりけり`}</em></strong>{`といったこともある。`}</p>
    <p>{`最後にビジネスでは、`}<strong parentName="p"><em parentName="strong">{`会議などでNvidia BroadCastアプリが騒音の多い環境では非常に重宝する`}</em></strong>{`だろう。`}</p>
    <h2 {...{
      "id": "Geforce-vs-Radeon-性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Geforce-vs-Radeon-%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Geforce vs Radeon 性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Geforce vs Radeon 性能比較表`}</h2>
    <p>{`最後にGeforceとRadeonの性能を示す。有名ベンチマークであるPassMark、3DMarkスコアと、ゲームを遊んだ時に期待できるFPSを各解像度ごとに併記している。表の見方や検索方法の例は下のメニューを開けて確認して欲しい。`}</p>
    <p>{`レンダリングにはDLSSやレイトレーシングは特に考慮されていないので、純粋なGPUとしての実力を測る指標といえる。`}</p>
    <Meyasu mdxType="Meyasu" />
    <Accordion title="検索/フィルタリング方法の例・詳細" mdxType="Accordion">
      <p>ヘッダー部分をクリックすることでソートが行える。また、GPU名称はカンマ区切りをすることでor条件で調べられ、各スコアはレンジ(-)や不等号でフィルタリングすることができる。</p>
      <AccentBox title="フィルター方法の例(該当するフォームへ入力)" mdxType="AccentBox">
  <li><span className="bold">RX
    </span>・・・RXシリーズのGPUのみを表示</li>
  <li><span className="bold">5000 - 10000</span>・・・スコアが5000から10000まで、-の間にはスペースが必要</li>
  <li><span className="bold">{'<'}100</span>・・・TDPが100W未満の省電力グラフィックボードのみを表示</li>
  <li><span className="bold">{'>'}200</span>・・・参考価格が200ドルより上</li>
      </AccentBox>
    </Accordion>
    <HikakuTable {...props} checkboxes={['geforce', 'radeon']} cpuData={props.data.allGeforceVsRadeonJson.nodes} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      